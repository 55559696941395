/* Importo il font dei caratteri personalizzato */

@font-face {
  font-family: Centra;
  src: url('./Assets/Fonts/CentraNo2-Bold.ttf');
  font-weight: 700; 
}

@font-face {
  font-family: Centra;
  src: url('./Assets/Fonts/CentraNo2-Medium.ttf');
  font-weight: 500; 
}

@font-face {
  font-family: Centra;
  src: url('./Assets/Fonts/CentraNo2-Book.ttf');
  font-weight: 400; 
}

/* CSS Predefinito per tutto il codice */

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative; 
  background-color: #121212 !important; 
  color: #fff !important; 
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  word-break: break-word; /* Aggiunto per evitare il trabocco */
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

/* Adattamento per schermi grandi */
@media (min-width: 1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

/* Stili aggiuntivi per dispositivi mobili */
@media (max-width: 600px) {
  main .container {
    max-width: 100%;
    padding: 0 15px; /* Ridurre il padding per schermi piccoli */
  }

  h1 {
    font-size: 1.5em; /* Ridurre la dimensione del font per schermi piccoli */
  }

  .txt-rotate, .wrap {
    display: block;
    white-space: normal; /* Permette il wrapping del testo */
  }

  .animate__animated {
    width: 100%; /* Assicura che le animazioni si adattino alla larghezza del contenitore */
  }
}

/* Barra di Navigazione */

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}

nav.navbar.scorrimento {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
    width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}

span.navbar-text {
    display: flex;
    align-items: center;
}

/*.social-icon {
    display: inline-block;
    margin-left: 14px;
}

.social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
    transform: scale(1);
}

.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}*/

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/* CSS d'Intestazione */

.intestazione {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./Assets/Img/anonymous.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.intestazione .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.intestazione h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.intestazione p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.intestazione button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.intestazione button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.intestazione button:hover svg {
  margin-left: 25px;
}

.intestazione img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/* CSS dei Talenti */

.talenti {
  padding: 0 0 50px 0;
  position: relative;
}

.talenti-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.talenti h2 {
	font-size: 45px;
	font-weight: 700;
}

.talenti p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}

.diapositive-talenti {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.diapositive-talenti .elemento img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.sfondo-talenti{
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/* CSS Miei lavori */

.lavori {
  padding: 80px 0;
  position: relative;
  background-color: black;
}

.lavori h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}

.lavori p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.lavori .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.lavori .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.lavori .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}

.lavori .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}

.lavori .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}

.lavori .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}

.nav-link#linguette-lavori-primo {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#linguette-lavori-secondo {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#linguette-lavori-terzo {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}

.lavori-img-bx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.lavori-img-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.lavori-img-bx:hover::before {
  height: 100%;
}

.lavori-img-txt {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}

.lavori-img-bx:hover .lavori-img-txt {
  top: 50%;
  opacity: 1;
}

.lavori-img-txt h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.lavori-img-txt span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* CSS del contatto */

.contatto {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}

.contatto #img-cellulare {
  width: 65%;
  padding-top: 15%;
}

.contatto h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}

.sfondo-contatto {
  background: rgba(255, 255, 255, 0.1); /* Imposta il colore di sfondo a bianco con un'opacità del 10% */
  backdrop-filter: blur(10px); /* Applica una sfocatura all'elemento di sfondo dietro il div */
  border-radius: 65px; /* Arrotonda gli angoli del div */
  padding: 60px; /* Aggiungi un po' di spazio intorno al contenuto del div */
}

.contatto form input, .contatto form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 18px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contatto form input:focus, .contatto form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contatto form input::placeholder, .contatto form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contatto form input:focus::placeholder, .contatto form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contatto form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contatto form button span {
  z-index: 1;
  position: relative;
}

.contatto form button:hover {
  color: #fff;
}

.contatto form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contatto form button:hover::before {
  width: 100%;
}

/* Spazio dedicabile per il bollettino informativo */

.bollettino {
  /*background: #FFFFFF; */
  background-image: url('./Assets/Img/hackFooter.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  border-radius: 55px;
  color: #ff3b3b;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}

.bollettino h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

/* Pié di pagina */

.pie {
  padding: 0 0 50px 0;
  background-image: url('./Assets/Img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.pie img {
  width: 26%;
}

.pie .logo-pie {
  width: 35%;
}

.pie p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.cuore {
  animation: battito 1s linear infinite;
}

@keyframes battito {
  0%, 100% {
    transform: scale(1);
  }
  25%, 50% {
    transform: scale(1.2);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
